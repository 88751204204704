import React, { useState } from 'react';
import Review from './Review.json';

import customerReviewPagination from '../../assets/images/customer-review-pagination.png';

const HomePagination = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const recordPage = 1;
  const lastIndexPage = currentPage * recordPage;
  const firstIndexPage = lastIndexPage - recordPage;
  const record = Review.slice(firstIndexPage, lastIndexPage);
  const npage = Math.ceil(Review.length / recordPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const changeCurrentPage = id => {
    setCurrentPage(id);
  };

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      {record.map((item, i) => (
        <div key={i} id="customer-review">
          <div>{item.review}</div>
        </div>
      ))}
      <div className="page-number">
        {number.map((n, i) => (
          <div className={`paginate-number ${currentPage === n ? 'active' : ''}`} key={i}>
            <div onClick={() => changeCurrentPage(n)}>
              0
              {n}
            </div>
          </div>
        ))}
        <div className="page-count">
          <div className={`nextPage ${currentPage === 1 ? 'active' : ''}`} onClick={previousPage}><img src={customerReviewPagination} alt="customerReviewPaginationImage" /></div>
          <div className={`nextPage ${currentPage !== 1 ? 'active' : ''}`} onClick={nextPage}><img src={customerReviewPagination} alt="customerReviewPaginationImage" /></div>
        </div>
      </div>
    </>
  );
};

export default HomePagination;
