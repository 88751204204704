import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ChangeCarrotLogo from './ChangeCarrotLogo';

import logo from '../assets/images/carrot-logo-orange.png';
import logoXmas from '../assets/images/carrot-logo-orange-xmas-crop.png'; // xmas

import xmark from '../assets/images/xmark.svg';
import Header from './Header';
import withText from '../assets/images/with.svg';

class Page extends React.Component {
  componentDidMount() {
    const { title } = this.props;
    document.title = `${title} | Carrot`;
  }

  render() {
    const {
      children, footer, hasHeader, hasLogo, hasImage, alignContentMobile, hm, back, background, noPrev, title,
      appLogo, dropdown,
    } = this.props;

    return (
      <div id="authentication">
        <div id="left" className={`${background} ${hasImage ? 'mobile-display-block' : 'mobile-display-none'}`}>
          <img className="xmark mobile-display-block" src={xmark} alt="x" />
        </div>
        <div id="right">
          <div className="">
            {hasHeader && <Header hm={hm} back={back} noPrev={noPrev} title={title} dropdown={dropdown} />}
            {hasLogo && (
              <div className="page-logo-div mobile-display-none ">
                <div className="partnerAuth-logo">
                  <Link to="/dashboard">
                    <ChangeCarrotLogo
                      imageXmas={<img src={logoXmas} alt="Carrot Logo" className="carrot" />}
                      image={<img src={logo} alt="Carrot Logo" className="carrot" />}
                    />
                  </Link>
                  {appLogo && (
                    <>
                      <img src={withText} alt="" className="with" />
                      <img src={/^http/.test(appLogo) ? appLogo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(appLogo)}`} alt="" className="partner" width="75px" />
                    </>
                  )}
                </div>
                <div />
              </div>
            )}

          </div>
          <div id="container" className={alignContentMobile}>
            {children}
          </div>
          <div id="footer" className="typography">{footer}</div>
        </div>
      </div>
    );
  }
}

Page.defaultProps = {
};

Page.propTypes = {
  alignContentMobile: PropTypes.oneOf(['align-left', 'align-center', 'align-right', '']).isRequired,
  back: PropTypes.func,
  background: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node.isRequired,
  hasHeader: PropTypes.bool,
  hasImage: PropTypes.bool,
  hasLogo: PropTypes.bool,
  hm: PropTypes.string, // header margin
  // lm: PropTypes.string, // logo margin
  noPrev: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

export default Page;
