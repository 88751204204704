import React, { Component } from 'react';
import { FiInfo } from 'react-icons/fi';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { BiRefresh } from 'react-icons/bi';

import { Button, Page, ChangeCarrotLogo } from '../../components';
import greyFrame from '../../assets/images/grey-frame.png';
import group from '../../assets/images/teller-icon.png';
import withText from '../../assets/images/with.svg';
import logo from '../../assets/images/carrot-logo-orange.png';
import logoXmas from '../../assets/images/carrot-logo-orange-xmas-crop.png'; // xmas

import * as Helpers from '../../helpers';

class Info extends Component {
  state = {
    modalShow: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.detectSize);

    const { getPortfolio, portfolio: { all: portfolioArray } } = this.props;
    if (portfolioArray.length <= 0) {
      getPortfolio(0);
    }
  }

  handleModal = modalState => {
    this.setState({ modalShow: modalState });
  }

  request = newAmount => () => {
    const {
      changeScreen, amount,
    } = this.props;
    if (amount > (Number(process.env.REACT_APP_MINIMUM_AMOUNT) / 100)) {
      if (newAmount === 'partial') {
        changeScreen();
      }
    } else {
      Helpers.notification.error(`Your credit line is less than $${Number(process.env.REACT_APP_MINIMUM_AMOUNT) / 100}.`);
    }
  }

  getQueryVariable = variable => {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=');
      if (pair[0] === variable) return pair[1];
    }
    return false;
  }

  refresh = () => {
    const { getPortfolio } = this.props;
    getPortfolio(Helpers.token.get('user:application_id'));
  }

  render() {
    const { modalShow } = this.state;
    const {
      amount, back, user: { loading }, applications,
    } = this.props;
    const application_id = Number(Helpers.token.get('user:application_id'));

    const appLogo = applications?.filter(vendor => Number(vendor.application_id) === application_id)[0]?.images.logo;
    const interest = applications?.filter(vendor => Number(vendor.application_id) === application_id)[0]?.interest;
    const portfolioLoading = loading.some(url => url === `/portfolio?application_id=${Helpers.token.get('user:application_id')}`);

    return (
      <Page
        title="Offer"
        alignContentMobile="align-center"
        back={back}
        background="illustration-2"
        hasHeader
        hm="130px 0 0 0"
        lm="100px 0 0 0"
        footer={<></>}
      >
        <div>
          <Modal
            show={modalShow}
            onHide={() => this.handleModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton />
            <Modal.Body>
              <img src={group} alt="" />
              <br />
              <br />
              <b>How we charge interests on credit</b>
              {' '}
              <p>
                <br />
                The interest rate is
                {' '}
                {interest}
                % per month. The minimum repayment expected per month is
                {' '}
                {interest * 2}
                %.
                {' '}
                <br />
                <br />
                This comprises a
                {' '}
                {interest}
                % interest rate and
                {' '}
                {interest}
                % repayment of the principal. This repayment plan will occur monthly until the credit&apos;s principal is fully repaid.
              </p>
            </Modal.Body>
          </Modal>
        </div>

        <div className="partnerAuth-logo">
          <Link to="/dashboard">
            <ChangeCarrotLogo
              imageXmas={<img src={logoXmas} alt="Carrot Logo" className="carrot" />}
              image={<img src={logo} alt="Carrot Logo" className="carrot" />}
            />
          </Link>
          {appLogo && (
            <>
              <img src={withText} alt="" className="with" />
              <img src={/^http/.test(appLogo) ? appLogo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(appLogo)}`} alt="" className="partner" width="75px" />
            </>
          )}
        </div>

        <div className="heading offer">
          You are eligible to receive an instant credit of up to
        </div>
        <div className="offer-amount-container">
          <div className="offer-amount">
            {
              portfolioLoading
                ? '*****'
                : `$${amount ? Helpers.formatMoney(amount, 2, true) : 0}`
            }
          </div>
          {portfolioLoading
            ? <div />
            : (
              <div className="offer-amount-refresh">
                <BiRefresh
                  className={`icon zls${loading.some(url => url === `/user?application_id=${application_id}` || url === `/portfolio?application_id=${application_id}`)
                    ? ' loading' : ''} icon-green`}
                  onClick={this.refresh}
                />
              </div>
            )}
        </div>

        {interest && (
          <div className="offer-info web-display-none mobile-display-block" onClick={() => this.handleModal(true)}>
            <FiInfo className="fi-info" />
            {' '}
            &nbsp;
            We charge
            {' '}
            {interest}
            % per month. click
            {' '}
            <u>here</u>
            {' '}
            to learn more
          </div>
        )}

        <div className="offer-center-image web-display-none mobile-display-block">
          <div className="offer-center-image-wrap">
            <img src={greyFrame} alt="Background" />
          </div>
        </div>

        <div className="offer-full-button">
          <Button
            onClick={this.request('partial')}
            size="large"
            background="light"
            loading={portfolioLoading}
          >
            REQUEST AN AMOUNT
          </Button>
        </div>

        {interest && (
          <div className="offer-info mobile-display-none" onClick={() => this.handleModal(true)}>
            <FiInfo className="fi-info" />
            {' '}
            &nbsp;
            We charge
            {' '}
            {interest}
            % per month. click
            {' '}
            <u>here</u>
            {' '}
            to learn more
          </div>
        )}

        <div className="footer offer">
          <Link style={{ textDecoration: 'none' }} to="/dashboard">
            <u>Go to Dashboard</u>
          </Link>
        </div>
      </Page>
    );
  }
}

export default Info;
