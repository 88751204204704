import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import ChangeCarrotLogo from './ChangeCarrotLogo';

import blackLogo from '../assets/images/carrot-logo-black.png';
import blackLogoXmas from '../assets/images/carrot-logo-orange-xmas-crop.png'; // xmas

const hamburger = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24" stroke="rgba(17, 17, 17, 1)"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" /></svg>;
const times = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="24" height="24" stroke="rgba(17, 17, 17, 1)"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>;

const Navbar = () => {
  const [toggleIcon, setToggleIcon] = useState(hamburger);
  const handleClickNav = e => {
    setToggleIcon(e.target.parentElement.classList.contains('collapsed') ? hamburger : times);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">
        <ChangeCarrotLogo
          imageXmas={<img src={blackLogoXmas} alt="Carrot logo" width="80" />}
          image={<img src={blackLogo} alt="Carrot logo" width="80" />}
        />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={handleClickNav}>
        {toggleIcon}
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <div className="midNavItem" />
        <ul className="navbar-nav mb-2 mb-lg-0 ml-auto paddingRight">
          <li className="nav-item">
            <a className="nav-link" href="/faqs">FAQs</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/signup">Register</a>
          </li>
        </ul>
        <div className="navButton noDisplaySm">
          <Link to="/signin"><Button cta>Log In</Button></Link>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
