import React from 'react';
import { Link } from 'react-router-dom';
import { countries } from 'countries-list';
import libphonenumber from 'google-libphonenumber';

import {
  Button, Page, Input, Tabs, DoubleInput, ChangeCarrotLogo
} from '../../components';
import ssl from '../../assets/images/ssl.svg';
import withText from '../../assets/images/with.svg';
import tickCircle from '../../assets/images/tick-circle-orange.svg';
import logo from '../../assets/images/carrot-logo-orange.png';
import logoXmas from '../../assets/images/carrot-logo-orange-xmas-crop.png'; // xmas

import * as Helpers from '../../helpers';

const countriesKeys = Object.keys(countries);
class Signup extends React.Component {
  state = {
    activeTab: 0,
    appName: '',
    application_id: 0,
    callingCode: '234',
    countryCode: 'NG',
    email: '',
    mobile: '',
    show: 'selectVendor',
  };

  secure = (
    <>
      <img src={ssl} alt="SSL" />
      {' '}
      By clicking &quot;SIGN UP&quot;, you acknowledge that you have read, understood, and agree to Carrot&apos;s
      {' '}
      <br />
      <Link style={{ color: '#FE5000' }} to="/user-agreement" target="_blank">User Agreement</Link>
      {' '}
      and
      {' '}
      <Link style={{ color: '#FE5000' }} to="/privacy-policy" target="_blank">Privacy Policy</Link>
      .
    </>
  );

  componentDidMount = () => {
    const { getAllApplications, application: { all: applications } } = this.props;
    if (applications.length === 0) {
      getAllApplications();
    }
  };

  change = type => e => this.setState({ [type]: e.target.value });

  setShow = choice => {
    this.setState({ show: choice });
  }

  back = trigger => () => {
    const { history } = this.props;
    history.push('/signin', {
      trigger,
    });
  }

  setApplicationId = (id, name) => {
    this.setState({ appName: name, application_id: id });
    Helpers.token.set(id, 'user:application_id');
  }

  handleSignup = () => {
    const {
      callingCode, email, mobile, activeTab,
    } = this.state;
    const emailOrMobile = activeTab === 0 ? mobile : email;
    const emailOrMobileTrim = emailOrMobile.trim();
    if (!emailOrMobileTrim) {
      Helpers.notification.error('To continue fill in the form correctly.');
    } else if (activeTab === 0) {
      const finalEmailOrMobile = `+${callingCode}${emailOrMobileTrim}`;
      const validateMobile = this.validatePhoneNumber(finalEmailOrMobile);
      if (validateMobile) {
        this.signup(finalEmailOrMobile);
      } else {
        Helpers.notification.error('Incorrect mobile number format!');
      }
    } else {
      this.signup(emailOrMobileTrim);
    }
  }

  signup = emailOrMobile => {
    const { application_id } = this.state;
    const { signup } = this.props;
    signup(
      application_id,
      emailOrMobile,
      {},
      response => {
        if (response && response.data && response.data.redirect) {
          Helpers.notification.success(response.message);
          setTimeout(() => {
            window.location = response.data.redirect;
          }, 1000);
        } else {
          Helpers.notification.success(response.message);
        }
      }
    );
  }

  setCountryCode = arg => {
    const countryCode = arg.target.value;
    const callingCode = countries[countryCode].phone;
    this.setState({ callingCode, countryCode });
  }

  validatePhoneNumber = mobile => {
    const { countryCode } = this.state;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    const country = { code: countryCode };

    if (process.env.REACT_APP_NODE_ENV !== 'production') {
      return true;
    }

    try {
      if (
        !phoneUtil.isValidNumberForRegion(
          phoneUtil.parse(
            mobile,
            country.code
          ),
          country.code
        )
      ) {
        return false;
      }
    } catch (e) {
      if (process.env.REACT_APP_NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      return false;
    }
    return true;
  }

  render() {
    const {
      email, mobile, show, application_id, countryCode, appName, activeTab, callingCode,
    } = this.state;
    const { user: { loading }, application: { all: applications } } = this.props;

    const appLogo = applications?.filter(vendor => vendor.application_id === application_id)[0]?.images.logo;

    switch (show) {
    case 'partnerAuth':
      return (
        <Page
          title="Signup"
          alignContentMobile="align-left"
          back={() => this.setShow('selectVendor')}
          background="illustration-2"
          hasHeader
          hm="70px 0 0 0"
          footer={<></>}
        >
          <div className="partnerAuth-logo">
            <ChangeCarrotLogo
              imageXmas={<img src={logoXmas} alt="Carrot Logo" className="carrot" />}
              image={<img src={logo} alt="Carrot Logo" className="carrot" />}
            />
            <img src={withText} alt="" className="with" />
            <img src={/^http/.test(appLogo) ? appLogo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(appLogo)}`} alt="" className="partner" width="75px" />
          </div>

          <div className="heading partnerAuth">
            Sign up with
            {' '}
            {Helpers.capitalizeFirstLetter3(appName)}
          </div>

          <div className="subheading partnerAuth">
            We will need the
            {' '}
            {activeTab === 0 ? 'mobile number' : 'email'}
            {' '}
            used in creating your
            {' '}
            <b>{Helpers.capitalizeFirstLetter3(appName)}</b>
            {' '}
            account to sign you up.
          </div>

          <Tabs
            current={num => this.setState({ activeTab: num })}
            tabs={[
              {
                component: (<div key={1} />),
                title: 'Phone Number',
              },
              {
                component: (<div key={2} />),
                title: 'Email',
              },
            ]}
          />

          {activeTab === 0 ? (
            <DoubleInput
              otherClasses="signin-input"
              leftInput={(
                <div className="mobile_code_select">
                  <Input
                    placeholder="Mobile"
                    type="select"
                    name="countryCode"
                    id="country_code_select"
                    onChange={this.setCountryCode}
                    value={countryCode}
                    options={[
                      { label: 'NG (+234)', value: 'NG' }, { label: 'US (+1)', value: 'US' },
                    ].concat(countriesKeys.map(country => ({
                      label: `${country} (+${countries[country].phone})`,
                      value: country,
                    })))}
                  />
                </div>
            )}
              rightInput={
                <Input placeholder="" onChange={this.change('mobile')} value={mobile} type="number" required />
            }
            />
          ) : (
            <div className="signin-input">
              <Input placeholder="Email" type="text" onChange={this.change('email')} value={email} />
            </div>
          )}

          <div className="signup-button">
            <Button loading={loading.some(url => (url === `/signon/${application_id}/${activeTab === 0 ? `+${callingCode}${mobile.trim()}` : email}`))} onClick={this.handleSignup} size="large" background="light">SIGN UP</Button>
          </div>

          <div className="footer signup">
            {this.secure}
          </div>

          <Link to="/signin">
            <div className="footer signin">
              Already have an account?
            </div>
          </Link>
        </Page>
      );
    case 'selectVendor':
      return (
        <Page
          title="Signup"
          alignContentMobile="align-left"
          back={this.back()}
          background="illustration-2"
          hasHeader
          hasLogo
          hm="100px 0 0 0"
          lm="53px 0 0 0"
          footer={<></>}
        >
          <div className="heading vendor">
            Select one of our partners to sign up
          </div>

          <div className="subheading vendor">
            We are consistently working on adding more fintech firms to this list.
          </div>

          <div className="vendor-cards">
            {applications.map((vendor, i) => (
              <button key={i} className="item highlight-light" onClick={() => this.setApplicationId(vendor.application_id, vendor.name)}>
                <img src={/^http/.test(vendor.images.logo) ? vendor.images.logo : `data:image/svg+xml;utf8;base64,${encodeURIComponent(vendor.images.logo)}`} alt="" className="vendor-logo" />
                {application_id === vendor.application_id && <img className="tick" src={tickCircle} alt="" />}
              </button>
            ))}
          </div>

          <div className="vendor-button">
            <Button
              disabled={application_id === 0}
              onClick={() => this.setShow('partnerAuth')}
              size="large"
              background="light"
            >
              SUBMIT
            </Button>
          </div>

          <Link to="/signin">
            <div className="footer signin">
              Already have an account?
            </div>
          </Link>
        </Page>
      );
    default:
      return null;
    }
  }
}

export default Signup;
